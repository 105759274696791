:root {
  --main-bg: #fff;
  --main-fg: #000;
  --navigation-button-bg: #ddd;
  --navigation-button-fg: #0d172a;
  --navigation-button-border: #e2e8f0;
  --navigation-button-current-bg: #087232;
  --navigation-button-current-fg: #fff;
  --navigation-button-hover-bg: #f3e600;
  --navigation-button-hover-fg: #087232;
  --checkbox-switch-bg: #fff;
  --checkbox-selected-bg: green;
  --checkbox-unselected-bg: red;
  --search-border: #ccc;
  --reset-button-bg: var(--navigation-button-current-bg);
  --reset-button-fg: var(--navigation-button-current-fg);
  --add-all-button-bg: var(--navigation-button-current-bg);
  --add-all-button-fg: var(--navigation-button-current-fg);
  --add-more-button-bg: var(--navigation-button-current-bg);
  --add-more-button-fg: var(--navigation-button-current-fg);
  --add-more-button-hover-bg: var(--navigation-button-hover-bg);
  --add-more-button-hover-fg: var(--navigation-button-hover-fg);
  --program-item-chevron-collapsed: #ccc;
  --program-item-chevron-expanded: #087232;
  --program-time-convention-message: grey;
  --program-time-local-message: grey;
  --program-date-line: #888;
  --program-time-line: #ccc;
  --program-item-line: #bbb;
  --program-tags-fg: #888;
  --program-expanded-bg: #eee;
  --permalink-icon-fg: #888;
  --permalink-icon-hover-fg: #087232;
  --participant-link-fg: #000;
  --participant-link-visited-fg: #444;
  --settings-outline: #888;
  --footer-line: #888;
  --error-message-fg: tomato;
  --shadow-014: #00000024;
  --shadow-012: #0000001f;
  --shadow-02: #0003;
  --shadow-03: #0000004d;
  --shadow-gray: #a6afc340;
  --debug-online-bg: green;
  --debug-online-fg: #fff;
  --debug-offline-bg: red;
  --debug-offline-fg: #fff;
}
img {
  height: auto;
  width: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-color: var(--main-bg);
  color: #000;
  color: var(--main-fg);
  margin: auto;
  max-width: 75rem;
}
.App {
  font-family: Roboto, Calibri, Open Sans, Lato, Helvetica Neue, Arial,
    sans-serif;
  margin: 1rem;
  text-align: left;
}
.date-heading,
.item-title,
h1,
h2,
h3,
h4 {
  font-family: Candara, Optima, Segoe, Segoe UI, sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}
header {
  justify-content: center;
  text-align: center;
}
header h1 {
  margin: 1rem 0;
}
.navigation ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  padding-inline-start: 0;
}
.navigation li {
  margin: 0.25em 0;
}
.navigation li a {
  background-color: #ddd;
  background-color: var(--navigation-button-bg);
  border: 0 solid #e2e8f0;
  border: 0 solid var(--navigation-button-border);
  box-shadow: 0 1px 2px #a6afc340;
  box-shadow: 0 1px 2px var(--shadow-gray);
  box-sizing: border-box;
  color: #0d172a;
  color: var(--navigation-button-fg);
  cursor: pointer;
  display: inline-block;
  font-family: Basier circle, -apple-system, system-ui, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1;
  padding: 0.6rem 1.1rem;
  text-align: center;
  text-decoration: none;
  text-decoration-thickness: auto;
  touch-action: manipulation;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  white-space: nowrap;
}
.navigation li a.active {
  background-color: #087232;
  background-color: var(--navigation-button-current-bg);
  color: #fff;
  color: var(--navigation-button-current-fg);
}
.navigation li a.active:hover,
.navigation li a:hover {
  background-color: #f3e600;
  background-color: var(--navigation-button-hover-bg);
  color: #087232;
  color: var(--navigation-button-hover-fg);
}
@media (min-width: 768px) {
  .navigation li a {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }
}
@media (max-width: 480px) {
  .navigation li a {
    padding: 0.5rem 0.7rem;
  }
}
.error {
  color: tomato;
  color: var(--error-message-fg);
  font-size: larger;
  margin: 3rem;
  text-align: center;
}
.error span {
  font-weight: 700;
}
div.switch-wrapper {
  font-size: smaller;
}
div.switch-wrapper label {
  max-height: 2em;
}
div.switch-wrapper,
div.switch-wrapper ~ div {
  clear: both;
  margin: 0.5em 1em;
}
div.program-empty {
  clear: both;
}
input.switch:empty {
  display: none;
}
input.switch:empty ~ label {
  cursor: pointer;
  float: left;
  line-height: 1.6em;
  margin: 0.2em 0;
  position: relative;
  text-indent: 3.4em;
  -webkit-user-select: none;
  user-select: none;
}
input.switch:empty ~ label:after,
input.switch:empty ~ label:before {
  background-color: red;
  background-color: var(--checkbox-unselected-bg);
  border-radius: 1em;
  bottom: 0;
  box-shadow: inset 0 0.2em 0 #0000004d;
  box-shadow: inset 0 0.2em 0 var(--shadow-03);
  content: " ";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.1s ease-in;
  width: 3em;
}
input.switch:empty ~ label:after {
  background-color: #fff;
  background-color: var(--checkbox-switch-bg);
  border-radius: 0.9em;
  bottom: 0.1em;
  box-shadow: inset 0 -0.2em 0 #0003;
  box-shadow: inset 0 -0.2em 0 var(--shadow-02);
  margin-left: 0.1em;
  top: 0.1em;
  width: 1.4em;
}
input.switch:checked ~ label:before {
  background-color: green;
  background-color: var(--checkbox-selected-bg);
}
input.switch:checked ~ label:after {
  margin-left: 1.5em;
}
.result-filters,
.search-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.25em;
  padding-inline-start: 0;
}
.stack {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-inline-start: 0;
}
.filter-locations,
.filter-options,
.filter-search,
.filter-tags {
  flex-basis: 0;
  flex-grow: 1;
  margin: 0.25em;
  z-index: 2;
}
.filter-locations,
.filter-search,
.filter-tags {
  font-size: 0.8em;
  max-width: 50ch;
  min-width: 20ch;
}
.stack {
  flex-basis: 0;
  flex-grow: 2;
  margin: 0.25em;
}
.filter-expand,
.filter-options {
  white-space: nowrap;
}
.filter-search,
.people-search {
  margin: auto 0.25em;
  max-width: 30ch;
}
.filter-search input,
.people-search input {
  border: 1px solid #ccc;
  border: 1px solid var(--search-border);
  border-radius: 4px;
  min-height: 38px;
}
.filter-expand {
  margin-top: 0.5em;
  max-width: 100ch;
}
.reset-filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.reset-button {
  background-color: #087232;
  background-color: var(--reset-button-bg);
  border: none;
  border-radius: 1.5rem;
  color: #fff;
  color: var(--reset-button-fg);
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}
.program-empty {
  font-style: italic;
  padding-top: 1rem;
}
.program-container {
  clear: both;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}
.time-convention-message {
  color: grey;
  color: var(--program-time-convention-message);
  font-weight: 700;
}
.time-local-message {
  color: grey;
  color: var(--program-time-local-message);
  font-style: italic;
}
.date-heading {
  background-color: #fff;
  background-color: var(--main-bg);
  border-bottom: 1px solid #888;
  border-bottom: 1px solid var(--program-date-line);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 0;
  padding-top: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.debug-mode .date-heading {
  padding-top: 3rem;
}
.timeslot {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid var(--program-time-line);
  display: flex;
  margin: 0.5rem;
}
.timeslot-time {
  flex-grow: 0;
  min-width: 9ch;
  vertical-align: top;
}
.timeslot-wide {
  min-width: 12ch;
}
.time-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  z-index: -1;
}
.debug-mode .time-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
}
.time-convention {
  font-weight: 700;
}
.time-local {
  font-size: 0.9em;
  font-style: italic;
  max-width: 9ch;
  padding-left: 0.2em;
}
.timeslot-wide .time-local {
  max-width: 12ch;
}
.timeslot-items {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  vertical-align: top;
}
.item {
  display: flex;
  margin-bottom: 0.25rem;
  max-width: 60ch;
}
.item ~ .item {
  border-top: 1px solid #bbb;
  border-top: 1px solid var(--program-item-line);
  padding-top: 0.5rem;
}
.item-selection {
  flex-grow: 0;
  min-width: 3ch;
  text-align: center;
  vertical-align: initial;
}
.item-entry {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 55ch;
  word-break: break-word;
}
.item-chevron {
  color: #ccc;
  color: var(--program-item-chevron-collapsed);
  float: right;
  font-size: 1.5rem;
  line-height: 1rem;
  overflow: auto;
}
.item-chevron-expanded {
  color: #087232;
  color: var(--program-item-chevron-expanded);
}
.item-title {
  font-weight: 700;
}
.item-duration,
.item-location,
.item-tags {
  color: #888;
  color: var(--program-tags-fg);
  font-size: 0.9em;
}
.item-duration,
.item-location,
.item-tag {
  display: inline-block;
  margin-right: 0.5rem;
}
.item-duration {
  white-space: nowrap;
}
.item-permalink {
  float: right;
}
.item-links a,
.item-permalink a {
  transition: color 0.4s;
}
.item-links a,
.item-links a:visited,
.item-permalink a,
.item-permalink a:visited {
  color: #888;
  color: var(--permalink-icon-fg);
}
.item-links a:hover,
.item-permalink a:hover {
  color: #087232;
  color: var(--permalink-icon-hover-fg);
}
.item-links {
  margin-top: 0.3rem;
}
.item-people {
  margin: 0.5rem 0;
}
.item-people ul {
  margin: 0;
  padding: 0;
}
.item-people .participant {
  display: inline-block;
  margin-right: 1rem;
}
.item-people .participant img {
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px 0 #0003;
  box-shadow: 0 2px 2px 0 var(--shadow-014), 0 3px 1px -2px var(--shadow-012),
    0 1px 5px 0 var(--shadow-02);
  height: 2rem;
  object-fit: cover;
  padding: 0.1rem;
  vertical-align: middle;
  width: 2rem;
}
.item-people div.participant-image {
  display: inline-block;
}
.program-container .participant .participant-image ~ span {
  padding-left: 0.3rem;
}
.item-details {
  overflow: hidden;
}
.item-details-expanded {
  background-color: #eee;
  background-color: var(--program-expanded-bg);
  border-radius: 0.5rem;
  overflow: auto;
  padding: 0.5rem;
}
.share-group {
  border: 1px solid #888;
  border: 1px solid var(--settings-outline);
  border-radius: 0.5rem;
  margin: 0.5em 0;
  padding: 0.5rem;
}
.share-head {
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.share-body {
  margin-bottom: 0.25rem;
}
.share-qr-code {
  margin: 0.25rem;
}
.result-more-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.show-more-button {
  background-color: #087232;
  background-color: var(--add-more-button-bg);
  border: none;
  color: #fff;
  color: var(--add-more-button-fg);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}
.show-more-button:hover {
  background-color: #f3e600;
  background-color: var(--add-more-button-hover-bg);
  color: #087232;
  color: var(--add-more-button-hover-fg);
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button-add-all {
  background-color: #087232;
  background-color: var(--add-all-button-bg);
  border: none;
  border-radius: 1.5rem;
  color: #fff;
  color: var(--add-all-button-fg);
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}
.people-settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.25em;
  padding-inline-start: 0;
}
.people ul {
  column-width: 25ch;
}
.participant a {
  color: #000;
  color: var(--participant-link-fg);
}
.participant a:active,
.participant a:hover,
.participant a:visited {
  color: #444;
  color: var(--participant-link-visited-fg);
}
.people .participant {
  break-inside: avoid-column;
  display: inline-block;
  list-style-type: none;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: 10rem;
}
.people .participant img {
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px 0 #0003;
  box-shadow: 0 2px 2px 0 var(--shadow-014), 0 3px 1px -2px var(--shadow-012),
    0 1px 5px 0 var(--shadow-02);
  height: 5rem;
  object-fit: cover;
  padding: 0.1rem;
  vertical-align: middle;
  width: 5rem;
}
.person {
  margin: 1rem 0;
}
.person-back-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
}
.person .person-image img {
  box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #0000001f, 0 1px 5px 0 #0003;
  box-shadow: 0 2px 2px 0 var(--shadow-014), 0 3px 1px -2px var(--shadow-012),
    0 1px 5px 0 var(--shadow-02);
  margin: 0 0.5rem 0.5rem 0;
  max-width: 10rem;
  padding: 0.3rem;
}
.person-tags {
  color: #888;
  color: var(--program-tags-fg);
  font-size: 0.9em;
  margin-bottom: 0.24rem;
}
.person .person-links {
  margin-top: 0.5rem;
  max-width: 60ch;
}
.person .person-links a {
  color: #000;
  color: var(--participant-link-fg);
}
.person .person-links a:active,
.person .person-links a:hover,
.person .person-links a:visited {
  color: #444;
  color: var(--participant-link-visited-fg);
}
.person .person-bio {
  max-width: 60ch;
}
.person .time-local-message {
  margin-top: 1rem;
}
.info {
  margin: 1rem;
  max-width: 60ch;
}
.settings-group {
  border: 1px solid #888;
  border: 1px solid var(--settings-outline);
  border-radius: 0.5rem;
  margin: 0.5em 0;
  padding: 0.5rem;
}
.settings-head {
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 0.5em;
}
.settings-radio {
  display: grid;
}
footer {
  margin-top: 2rem;
  text-align: right;
}
@media print {
  .filter,
  .navigation,
  .people-settings {
    display: none;
  }
  .participant a {
    text-decoration: none;
  }
}
.uninteractive .item-selection {
  display: none;
}
.uninteractive .item-details {
  background-color: #eee;
  background-color: var(--program-expanded-bg);
  border-radius: 0.5rem;
  max-height: 40rem;
  overflow: auto;
  padding: 0.5rem;
}
div.uninteractive {
  display: flex;
  flex-direction: column;
}
div.uninteractive div.info,
div.uninteractive div.program-page {
  width: 100%;
}
@media screen and (min-width: 1000px) {
  div.uninteractive {
    flex-direction: row;
  }
  div.uninteractive div.info,
  div.uninteractive div.program-page {
    width: 50%;
  }
}
.debug {
  left: 0;
  padding: 0.25rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.debug-online {
  background-color: green;
  background-color: var(--debug-online-bg);
  color: #fff;
  color: var(--debug-online-fg);
}
.debug-offline {
  background-color: red;
  background-color: var(--debug-offline-bg);
  color: #fff;
  color: var(--debug-offline-fg);
}
.debug span {
  padding: 0 1em;
}
